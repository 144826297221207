<template>
  <div>
    <v-chart
      :force-fit="true"
      :height="height"
      :padding="padding"
      :data="data"
      :scale="scale"
    >
      <v-tooltip :show-title="false" :item-tpl="tooltipOpts.itemTpl" />
      <v-legend :useHtml="true" :flipPage="true"></v-legend>
      <v-coord type="rect" direction="LT" />
      <v-pyramid
        :position="funnelOpts.position"
        :color="funnelOpts.color"
        :label="funnelOpts.label"
        :tooltip="funnelOpts.tooltip"
      />
      <v-guide
        v-for="(obj, index) in data"
        :key="index"
        type="text"
        :top="true"
        :position="getPosition(obj)"
        :content="getContent(obj)"
        :vStyle="style"
      />
    </v-chart>
  </div>
</template>
<script>
const DataSet = require("@antv/data-set");
const dv = new DataSet.View();
const scale = {
  dataKey: "percent",
  nice: false
};
const tooltipOpts = {
  showTitle: false,
  itemTpl:
    '<li data-index={index} style="margin-bottom:4px;">' +
    '<span style="background-color:{color};" class="g2-tooltip-marker"></span>' +
    "{name}<br/>" +
    '<span style="padding-left: 16px">行为次数：{pv}</span><br/>' +
    '<span style="padding-left: 16px">占比：{percent}</span><br/>' +
    "</li>"
};
const funnelOpts = {
  position: "action*percent",
  color: [
    "action",
    [
      "#0190FF",
      "rgb(12, 150, 12)",
      "rgb(64, 72, 72)",
      "rgb(211, 190, 52)",
      "rgb(129, 73, 151)",
      "rgb(128, 230, 220)",
      "rgb(227, 91, 57)"
    ]
  ],
  label: [
    "action*pv",
    (action, pv) => {
      return pv;
    },
    {
      offset: 35,
      labelLine: {
        lineWidth: 1,
        stroke: "rgba(0, 0, 0, 0.15)"
      }
    }
  ],
  tooltip: [
    "action*pv*percent",
    (action, pv, percent) => ({
      name: action,
      percent: Math.floor(percent * 100) + "%",
      pv: pv
    })
  ]
};

export default {
  props: {
    parentData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      data: null,
      padding: [10, 60, 65, 5],
      scale,
      dv: dv,
      height: 300,
      tooltipOpts,
      funnelOpts,
      style: {
        fill: "#fff",
        fontSize: "12",
        textAlign: "center",
        shadowBlur: 2,
        shadowColor: "rgba(0, 0, 0, .45)"
      }
    };
  },
  methods: {
    getPosition: obj => {
      return {
        action: obj.action,
        percent: "median"
      };
    },
    getContent: obj => {
      return parseInt(String(obj.percent * 100)) + "%";
    }
  },
  mounted() {
    let pData = this.parentData;
    let sourceData = [];
    sourceData.push({ action: "用户注册", pv: pData.registerCount });
    sourceData.push({ action: "登录系统", pv: pData.loginCount });
    sourceData.push({ action: "完成认证", pv: pData.finishVerify });
    sourceData.push({ action: "创建工地", pv: pData.createProject });
    sourceData.push({ action: "上班打卡", pv: pData.onWork });
    sourceData.push({ action: "创建订单", pv: pData.createOrder });
    sourceData.push({ action: "完成结算", pv: pData.finishSettlement });
    this.dv.source(sourceData);
    this.data = this.dv.rows;
    this.dv.transform({
      type: "percent",
      field: "pv",
      dimension: "action",
      as: "percent"
    });
  }
};
</script>
<style>
.el-card__header {
  border-bottom: none;
}
</style>
