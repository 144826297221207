<template>
  <div :style="[{ height: height + 'px' }]" class="home_container">
    <el-row style="margin-left:15px;margin-right:15px;">
      <panel-group v-if="data !== null" :parentData="data" />
    </el-row>
    <el-row>
      <el-col :span="14">
        <el-card class="box-card" style="margin-right:5px;">
          <div slot="header">
            用户走势<span style="padding-left:15px;color: #bab4b4;"
              >近一年</span
            >
          </div>
          <user-trend-chart v-if="userTrend.length>0" :parentData="userTrend" />
        </el-card>
      </el-col>
      <el-col :span="10">
        <el-card style="margin-left:5px;" class="box-card">
          <div slot="header">
            用户行为分析<span style="padding-left:15px;color: #bab4b4;"
              >近一年</span
            >
          </div>
          <funnel-plots v-if="data !== null" :parentData="data.userAnalyze" />
        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="14">
        <el-card class="box-card" style="margin-right:5px;">
          <div slot="header">
            订单|结算<span style="padding-left:10px;color: #bab4b4;"
              >近一年</span
            >
          </div>
          <order-trend-chart v-if="orderTrend.length>0" :parentData="orderTrend" />
        </el-card>
      </el-col>
      <el-col :span="10">
        <el-card style="margin-left:5px;" class="box-card">
          <div slot="header">
            用户激活<span style="padding-left:10px;color: #bab4b4;"
              >近一年</span
            >
          </div>
          <pie-chart :parentData="activeUser" v-if="data !== null" />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { homeData } from "../../api/home";
import PanelGroup from "./components/PanelGroup";
import UserTrendChart from "./components/UserTrendChart";
import OrderTrendChart from "./components/OrderTrendChart";
import FunnelPlots from "./components/FunnelPlots";
import PieChart from "./components/PieChart";
export default {
  name: "Dashboard",
  components: {
    PanelGroup,
    OrderTrendChart,
    UserTrendChart,
    FunnelPlots,
    PieChart
  },
  data() {
    return {
      data: null, // 全部数据
      userTrend: [], // 用户走势数据
      orderTrend: [], //订单|结算数据
      activeUser: [], //用户激活数据
      height: document.documentElement.clientHeight - 80 //实时屏幕高度
    };
  },
  methods: {
    getData() {
      homeData().then(res => {
        this.data = res.data;
        this.userTrend = [];
        this.orderTrend = [];
        // 用户走势数据处理
        res.data.userTrends.forEach(item => {
          let row = {
            month: item.mounth,
            注册: item.registerCount,
            认证: item.verifyCount
          };
          this.userTrend.push(row);
        });

        // 订单|结算数据处理
        res.data.orderTrends.forEach(item => {
          let row = {
            month: item.mounth,
            订单数: item.orderCount,
            结算数: item.settlementCount
          };
          this.orderTrend.push(row);
        });
        // 用户激活数据处理
        this.activeUser.push({ item: "激活", count: res.data.activeUserCount })
        this.activeUser.push({ item: "待激活", count: res.data.notActiveUserCount })
      });
    }
  },
  mounted() {
    let that = this;
    // <!--把window.onresize事件挂在到mounted函数上-->
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        that.height = window.fullHeight - 80; // 高
      })();
    };
    this.getData();
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.dashboard {
  &-container {
    background: url("/static/dashboard.svg") no-repeat 50%;
    height: 680px;
  }
  &-text {
    font-size: 30px;
    line-height: 46px;
  }
}
.home_container {
  margin: 5px 10px 60px 10px !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
.home_container::-webkit-scrollbar {
  display: none;
}
.home_container {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
</style>
