<template>
  <div>
    <v-chart :force-fit="true" :padding="padding" :height="height" :data="data" :scale="scale">
      <v-tooltip />
      <v-axis />
      <v-legend />
      <v-line position="month*temperature" color="city" shape="smooth" />
      <v-point position="month*temperature" color="city" :size="4" :v-style="style" :shape="'circle'" />
    </v-chart>
  </div>
</template>
<script>
const scale = [
  {
    dataKey: 'month',
    min: 0,
    max: 1
  }
]

export default {
  props: {
    parentData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      data: null,
      dv: null,
      padding: [10, 10, 60, 20],
      scale,
      height: 300,
      style: { stroke: '#fff', lineWidth: 1 }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const DataSet = require('@antv/data-set')
      this.dv = new DataSet.View()
      this.dv.source(this.parentData)
      this.dv.transform({
        type: 'fold',
        fields: ['注册', '认证'],
        key: 'city',
        value: 'temperature'
      })
      this.data = this.dv.rows
    }
  }
}
</script>
