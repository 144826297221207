<template>
  <el-row :gutter="40" class="panel-group">
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel">
        <div class="card-panel-icon-wrapper icon-people">
          <div class="time-label">近一年</div>
          <svg-icon icon-class="运单" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <count-to
            :start-val="0"
            :end-val="parentData.orderCount"
            :duration="2600"
            class="card-panel-num"
          />
          <div class="card-panel-text">
            总运单数
          </div>
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel">
        <div class="card-panel-icon-wrapper icon-message">
          <div class="time-label">近一年</div>
          <svg-icon icon-class="吨位" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <count-to
            :start-val="0"
            :end-val="parentData.zdw"
            :duration="3000"
            class="card-panel-num"
          />
          <div class="card-panel-text">
            总吨位
          </div>
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel">
        <div class="card-panel-icon-wrapper icon-money">
          <div class="time-label">近一年</div>
          <svg-icon icon-class="饼图" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <count-to
            :start-val="0"
            :end-val="parentData.tpscdw"
            :duration="3200"
            class="card-panel-num"
          />
          <div class="card-panel-text">
            土票售出吨位
          </div>
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel">
        <div class="card-panel-icon-wrapper icon-shopping">
          <div class="time-label">近30日</div>
          <svg-icon icon-class="折线" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <count-to
            :start-val="0"
            :end-val="parentData.cjdw"
            :duration="3600"
            class="card-panel-num"
          />
          <div class="card-panel-text">
            成交吨位
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import CountTo from "vue-count-to";
export default {
  props: {
    parentData: {
      type: Object,
      default:()=>{}
    },
  },
  data() {
    return {};
  },
  components: {
    CountTo
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>
.panel-group {
  margin-top: 8px;
  .card-panel-col {
    margin-bottom: 10px;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .card-panel {
    height: 108px;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.05);

    // &:hover {
    //   .card-panel-icon-wrapper {
    //     color: #fff;
    //   }

    //   .icon-people {
    //     background: #40c9c6;
    //   }

    //   .icon-message {
    //     background: #36a3f7;
    //   }

    //   .icon-money {
    //     background: #f4516c;
    //   }

    //   .icon-shopping {
    //     background: #34bfa3;
    //   }
    // }

    .icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-shopping {
      color: #34bfa3;
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 20px 0 0 14px;
      padding: 5px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      margin-left: 40px;
      margin-top: 20px;
      font-size: 40px;
    }
    .time-label {
      margin-top: -20px;
      margin-left: -10px;
      font-size: 16px;
      color: rgb(186, 180, 180);
    }
    .card-panel-description {
      width: 100%;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        padding-top: 10px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 12px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 600px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
</style>
