<template>
  <div>
    <v-chart
      :forceFit="true"
      :padding="padding"
      :height="height"
      :data="data"
      :scale="scale"
    >
      <v-tooltip :showTitle="false" dataKey="item*percent" />
      <v-axis />
      <v-legend dataKey="item" />
      <v-pie
        position="percent"
        color="item"
        :vStyle="pieStyle"
        :label="labelConfig"
      />
      <v-coord type="theta" :radius="0.75" :innerRadius="0.6" />
      <v-guide
        type="text"
        :top="true"
        :position="['45%', '50%']"
        :content="guidContent"
        :style="guidStyle"
      >
      </v-guide>
    </v-chart>
  </div>
</template>

<script>
const DataSet = require("@antv/data-set");
const scale = [
  {
    dataKey: "percent",
    min: 0,
    formatter: ".0%"
  }
];
const dv = new DataSet.View();
export default {
  props: {
    parentData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      data: null,
      dv: dv,
      scale,
      padding: [8, 10, 40, 10],
      height: 300,
      guidStyle: {
        fontSize: "30",
        fill: "#262626",
        textAlign: "center"
      },
      guidContent: "总：0人",
      pieStyle: {
        stroke: "#fff",
        lineWidth: 1 
      },
      labelConfig: [
        "percent",
        {
          formatter: (val, item) => {
            return item.point.item + ": " + val;
          }
        }
      ]
    };
  },
  mounted() {
    let total = 0;
    this.parentData.forEach(item => {
      total += item.count;
    });
    this.guidContent = "总:" + total + "人";
    this.dv.source(this.parentData);
    this.dv.transform({
      type: "percent",
      field: "count",
      dimension: "item",
      as: "percent"
    });
    this.data = this.dv.rows;
  }
};
</script>
