import request from '@/utils/http'

// 首页数据查询
export function homeData(params) {
  return request({
    url: '/view/index',
    method: 'get',
    params
  })
}
